import aa from 'search-insights';
import { useRouter } from 'next/router';
import { useCustomer } from '@services/shopify';
import { ALGOLIA_SEARCH_INDEXES, LOCALE_DICT, getShopifyIdFromGID } from '@utils/index';

export default function useAlgoliaTracking() {
	const { data: customer } = useCustomer();
	const { locale } = useRouter();
	const countryCode = LOCALE_DICT[locale].countryCode;
	const userToken = `${customer ? getShopifyIdFromGID(customer.id) : `guest-user-${Math.floor(Math.random() * 1000000)}`}`;

	return id =>
		aa('viewedObjectIDs', {
			userToken,
			index: ALGOLIA_SEARCH_INDEXES[countryCode].SHOPIFY_PRODUCTS,
			eventName: 'Product Viewed',
			objectIDs: [id],
		});
}
