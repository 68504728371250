import React from 'react';
import cn from 'classnames';
import { useIsMobile } from '@utils/hooks';
import { LARGE_WIDTH } from '@constants';
import Flex from '../Flex';
import styles from '../Carousel/Carousel.module.scss';
import Skeleton from './Skeleton';

const CarouselSkeleton = () => {
	const isMobile = useIsMobile({ maxWidth: LARGE_WIDTH });
	return (
		<Flex className={cn(styles['carousel-container'])} gap={4}>
			{!isMobile && (
				<Flex column gap={3}>
					{[...Array(5)].map((__, i) => (
						<Skeleton key={i} height={72} width={72} borderRadius={'2rem'} type={'rect'} />
					))}
				</Flex>
			)}
			<div style={{ width: '100%' }}>
				<Skeleton height={600} type={'rect'} />
			</div>
		</Flex>
	);
};

export default CarouselSkeleton;
