import React from 'react';
import { useIsMobile } from '@utils/hooks';
import { LARGE_WIDTH } from '@constants';
import styles from '../../products/AboutValueProp/AboutValueProp.module.scss';
import Divider from '../Divider';
import Skeleton from './Skeleton';

const AboutValueSkeleton = () => {
	const isMobile = useIsMobile({ maxWidth: LARGE_WIDTH });
	return (
		<div className={styles.container}>
			<Skeleton width={400} height={20} type='line' />
			<Divider />
			<div className={styles.cardGrid}>
				{[...Array(3)].map((__, i) => (
					<Skeleton key={i} height={500} width={isMobile ? 400 : 500} borderRadius={'2rem'} type={'rect'} />
				))}
			</div>
		</div>
	);
};

export default AboutValueSkeleton;
